<template>
  <v-card>
    <v-app-bar flat>
      <v-toolbar-title class="title pl-0">
        Account Management - My Details
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="refetch()" >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container>
      <v-row v-if="user">
        <v-col cols="12">
          <v-list>
            <v-list-item v-for="(prop, key) in user" :key="key" v-show="!toHide.includes(key)">
              <v-list-item-content>
                <v-list-item-title style="text-transform: capitalize">{{ key }}</v-list-item-title>
                <v-list-item-subtitle>{{ prop }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-btn @click="logout()">Logout</v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          You need to be logged in to view your details.<br />
          <router-link :to="'signin'">Click here</router-link> to login.
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  export default {
    name: 'account',
    data: () => ({
      toHide: [ 'oldData', 'classes', 'courses', 'roles', 'lastSignInTime' ]
    }),
    computed: {
      user () {
        return this.$store.getters.user
      }
    },
    methods: {
      logout () {
        this.$store.dispatch('logout')
        this.$router.push('/signin')
      },
      refetch () {
        this.$store.dispatch('getExtraUserData')
      }
    }
  }
</script>
